import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Us About Commerce Chatbots'}
        messages={[
          'Increase conversions and monetization',
          'Reduce drop-off and abandonment',
          'Identify unhandled and mis-handled user Intents',
          'Segment and retarget users through broadcast messages',
        ]}
      />
    </Layout>
  );
};

export default Contact;
